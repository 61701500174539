import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('../views/pages/account/register'),
    //     meta: {
    //         beforeResolve(routeTo, routeFrom, next) {
    //             // If the user is already logged in
    //             if (store.getters['auth/loggedIn']) {
    //                 // Redirect to the home page instead
    //                 next({ name: 'home' })
    //             } else {
    //                 // Continue to the login page
    //                 next()
    //             }
    //         },
    //     },
    // },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password/confirmation',
        name: 'forgot-password.confirmation',
        component: () => import('../views/pages/account/forgot-password-form'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/change_password',
        name: 'change_password',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/change_password/index')
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/machine',
        name: 'machine',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/machine/index')
    },
    {
        path: '/machine_type',
        name: 'machine_type',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/machine_type/index')
    },
    {
        path: '/outlet',
        name: 'outlet',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/outlet/index')
    },
    {
        path: '/advertisement',
        name: 'advertisement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advertisement/index')
    },
    {
        path: '/advertisement_type',
        name: 'advertisement_type',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/advertisement_type/index')
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/product/index')
    },
    {
        path: '/product_type',
        name: 'product_type',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/product_type/index')
    },
    {
        path: '/cashbox',
        name: 'cashbox',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/cashbox/index')
    },
    {
        path: '/surplus',
        name: 'surplus',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/surplus/index')
    },
    {
        path: '/cashbox/placement',
        name: 'cashbox.placement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/cashbox/placement/index')
    },
    {
        path: '/inventory',
        name: 'inventory',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/inventory/index')
    },
    {
        path: '/inventory/issue',
        name: 'inventory_issue',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/inventory_issue/index')
    },
    {
        path: '/principle',
        name: 'principle',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/principle/index')
    },
    {
        path: '/transactions',
        name: 'transactions',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/transactions/index')
    },
    {
        path: '/warehouse',
        name: 'warehouse',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/warehouse/index'),
    },
    {
        path: '/region',
        name: 'region',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/warehouse/region/index'),
    },
    {
        path: '/warehouse/inventory',
        name: 'warehouse.inventory',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/warehouse/inventory/index'),
    },
    {
        path: '/vehicle',
        name: 'vehicle',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/vehicle/index')
    },
    {
        path: '/work',
        name: 'work',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/work/index')
    },
    {
        path: '/movement',
        name: 'movement',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/movement/index')
    },
    {
        path: '/report',
        name: 'report',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/report/index')
    },
    {
        path: '/report/monthly',
        name: 'report.monthly',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/report_monthly/index')
    },
    {
        path: '/report/yearly',
        name: 'report.yearly',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/report_yearly/index')
    },
    {
        path: '/site',
        name: 'site',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/site/index')
    },
    {
        path: '/route',
        name: 'route',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/route/index')
    },
    {
        path: '/supplier',
        name: 'supplier',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/supplier/index')
    },
    // {
    //     path: '/purchase',
    //     name: 'purchase',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/purchase/index')
    // },
    // {
    //     path: '/purchase/receive',
    //     name: 'purchase.receive',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/purchase/receive/index'),
    // },
    {
        path: '/receive',
        name: 'receive',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/receive/index')
    },
    {
        path: '/user',
        name: 'user',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user/index')
    },
    {
        path: '/user/role',
        name: 'user.role',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/user_role/index')
    },
    // {
    //     path: '/user-role',
    //     name: 'user.role',
    //     meta: {
    //         authRequired: true,
    //     },
    //     component: () => import('../views/pages/user_role/index')
    // },
    {
        path: '/role',
        name: 'role',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/role/index')
    },
    {
        path: '/permission',
        name: 'permission',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/permission/index')
    },
]
