
import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const userService = {
    login,
    logout,
    register,
    getAll,
    createOrUpdate,
    getById,
    deleteById,
    changePassword,
    adminChangePassword,
    updateUserRole,
    // getAllRoles,
    forgotPassword,
    forgotPasswordForm,
};


function login(email, password, rememberMe) {

    let formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)
    formData.append('rememberMe', rememberMe)
    const requestOptions = {
        url: `/api/v1/login`,
        method: 'POST',
        data: formData
    }

    return axiosInstance(requestOptions)
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user))
            }
            return user
        })
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user')
}

function register(user) {
    const requestOptions = {
        url: `/users/register`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(user)
    }
    return axiosInstance(requestOptions)
}

function getById(machineTypeId) {
    const requestOptions = {
        url: '/api/v1/user/user/' + machineTypeId,
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

function getAll() {
    // console.log(process.env)
    const requestOptions = {
        url: '/api/v1/user/user',
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {
    let formData = new FormData();
    jsonData['ID'] = jsonData['id']
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key])
    }
    let rMethod = jsonData["id"] != null ? "PUT" : "POST"
    const requestOptions = {
        url: '/api/v1/user/user',
        method: rMethod,
        data: formData,
        headers: authHeader()
    }

    return axiosInstance(requestOptions)
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/user/` + id,
        method: 'DELETE',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}


function changePassword(jsonData) {
    let formData = new FormData();
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = "PUT"
    const requestOptions = {
        url : '/api/v1/user/user/change_password',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function adminChangePassword(jsonData) {
    let formData = new FormData();
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = "PUT"
    const requestOptions = {
        url : '/api/v1/user/user/admin/change_password',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

// function getAllRoles() {
//     const requestOptions = {
//         url : '/api/v1/user/role',
//         method: 'GET',
//         headers: authHeader()
//     };
//     return axiosInstance(requestOptions)
// }

function updateUserRole(jsonData) {
    let formData = new FormData();
    jsonData['ID'] = jsonData['id']
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = "PUT"
    const requestOptions = {
        url : '/api/v1/user/user/admin/change_role',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function forgotPassword(jsonData) {
    let formData = new FormData();
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = "POST"
    const requestOptions = {
        url : '/api/v1/user/forgot_password',
        method: rMethod,
        data: formData,
    };

    return axiosInstance(requestOptions);
}

function forgotPasswordForm(jsonData) {
    let formData = new FormData();
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = "PUT"
    const requestOptions = {
        url : '/api/v1/user/forgot_password',
        method: rMethod,
        data: formData,
    };

    return axiosInstance(requestOptions);
}